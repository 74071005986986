import * as React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { clsx } from "clsx";
import { cn } from "~/utils";

type CustomInputProps = {
  error?: string | undefined;
  helpText?: string;
  label?: string;
  labelFor?: string;
  wrapperClass?: string;
};

function inputClasses({
  error,
  leadingIcon = false,
}: {
  error: boolean | undefined;
  leadingIcon?: boolean;
}) {
  return clsx(
    "block w-full rounded-md border-gray-300 shadow-sm sm:text-sm text-gray-1000",
    {
      "focus:border-brand focus:ring-0 placeholder:text-[#666] placeholder:opacity-50":
        !error,
      "border-error/40 pr-10 text-error placeholder-error/40 focus:border-error focus:outline-none focus:ring-error":
        error,
    },
    leadingIcon && "pl-10",
  );
}

type InputProps = CustomInputProps &
  React.InputHTMLAttributes<HTMLInputElement>;

type TextAreaProps = CustomInputProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>;

type CheckboxProps = CustomInputProps &
  React.InputHTMLAttributes<HTMLInputElement>;

type RadioProps = CustomInputProps &
  React.InputHTMLAttributes<HTMLInputElement>;

function Error({ error }: { error: string }) {
  return <p className="mt-2 text-sm text-error">{error}</p>;
}

function Label({
  id,
  label,
  error,
}: {
  id: string | undefined;
  label: string;
  error: boolean;
}) {
  return (
    <label
      htmlFor={id}
      className={clsx("block text-sm font-bold text-start", {
        "text-brand": !error,
        "text-error": error,
      })}
    >
      {label}
    </label>
  );
}

function HelpText({
  id,
  helpText,
}: {
  id: string | undefined;
  helpText: string;
}) {
  return (
    <p
      className="t-3 text-sm leading-6 text-gray-600 mt-3"
      id={`${id}-description`}
    >
      {helpText}
    </p>
  );
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ error, helpText, label, labelFor, wrapperClass, ...rest }, ref) => {
    const { id } = rest;
    return (
      <div className={wrapperClass ? wrapperClass : ""}>
        {label && <Label id={id} label={label} error={Boolean(error)} />}
        <div
          className={clsx("mt-2", {
            "relative rounded-md shadow-sm": error,
          })}
        >
          <input
            ref={ref}
            className={inputClasses({
              error: Boolean(error),
            })}
            {...rest}
          />

          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-error"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {helpText && <HelpText id={id} helpText={helpText} />}
        {error && <Error error={error} />}
      </div>
    );
  },
);

Input.displayName = "Input";

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ error, helpText, label, labelFor, wrapperClass, ...rest }, ref) => {
    const { id } = rest;
    return (
      <div className={wrapperClass ? wrapperClass : ""}>
        {label && <Label id={id} label={label} error={Boolean(error)} />}
        <div
          className={clsx("mt-2", {
            "relative rounded-md shadow-sm": error,
          })}
        >
          <textarea
            ref={ref}
            className={inputClasses({
              error: Boolean(error),
            })}
            {...rest}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-error"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {helpText && <HelpText id={id} helpText={helpText} />}
        {error && <Error error={error} />}
      </div>
    );
  },
);

TextArea.displayName = "TextArea";

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ error, helpText, label, ...rest }, ref) => {
    const { id } = rest;
    const labelOrHelpText = Boolean(label || helpText);
    return (
      <div className={clsx("", labelOrHelpText && "space-y-5")}>
        <div
          className={clsx("", labelOrHelpText && "relative flex items-start")}
        >
          <div className={clsx("", labelOrHelpText && "flex items-center")}>
            <input
              {...rest}
              ref={ref}
              className={cn(
                "focus:ring-accent h-4 w-4 text-accent border-brand rounded",
              )}
            />
            {labelOrHelpText && (
              <div className="ml-3 text-sm leading-6">
                {label && (
                  <Label id={id} label={label} error={Boolean(error)} />
                )}
                {helpText && (
                  <label htmlFor={id}>
                    <HelpText id={id} helpText={helpText} />
                  </label>
                )}
              </div>
            )}

            {error && <Error error={error} />}
          </div>
        </div>
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ error, label, value, name, required, ...rest }, ref) => {
    const { id } = rest;

    return (
      <div className="mt-2">
        <div className="flex flex-nowrap items-center mb-2">
          <input
            value={value}
            name={name}
            required={Boolean(required)}
            type="radio"
            ref={ref}
            {...rest}
            id={`${label}-${id}`}
          />

          <label
            className="py-2 inline-block w-full cursor-pointer text-md ml-2 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            htmlFor={`${label}-${id}`}
          >
            {label}
          </label>
        </div>

        {error ? <Error error={error} /> : null}
      </div>
    );
  },
);

Radio.displayName = "Radio";

const PercentInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ error, helpText, label, labelFor, wrapperClass, ...rest }, ref) => {
    const { id } = rest;
    return (
      <div className={wrapperClass ? wrapperClass : ""}>
        {label && <Label id={id} label={label} error={Boolean(error)} />}
        <div
          className={clsx("relative mt-2", {
            "rounded-md shadow-sm": error,
          })}
        >
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-700 sm:text-sm">%</span>
          </div>
          <input
            ref={ref}
            className={inputClasses({
              leadingIcon: true,
              error: Boolean(error),
            })}
            placeholder={rest.placeholder || "0.00"}
            step={rest.step || "0.01"}
            {...rest}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-error"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {helpText && <HelpText id={id} helpText={helpText} />}
        {error && <Error error={error} />}
      </div>
    );
  },
);

PercentInput.displayName = "PercentInput";

export {
  Checkbox,
  Error,
  HelpText,
  Input,
  Label,
  Radio,
  PercentInput,
  TextArea,
  inputClasses,
};

export type { CustomInputProps, TextAreaProps, CheckboxProps, InputProps };
